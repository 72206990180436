import React from 'react';
import './bootstrap.css';
import './App.css';
import { BrowserRouter as HashRouter, Route, Switch } from "react-router-dom";

import Sidebar from './components/sidebar';
import Home from './components/home/index';
import Contact from './components/contact/index';

function App() {
  return (
    <HashRouter basename="/">
      <div>
        <Sidebar/>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/contact" component={Contact}/>
        </Switch>
      </div>
    </HashRouter>
  );
}

export default App;
