import React from 'react';
import me from '../../assets/me.jpg';

class Home extends React.Component {
    render() {
        return (
            <div className="background main">
                <div className="align-center">
                    <img src={me} alt="Lewis Carter" className="avatar" />
                </div>
                <div className="jumbotron">
                    <h1 className="display-3">Contact Details</h1>
                    <hr className="my-4"></hr>
                    <p className="jumbotron-p">
                        There are a number of ways to contact me, pick your poison below!
                        <br/>
                        <br/>
                        The most reliable way is to email me at: <a href="mailto:lewiscarter998@gmail.com">lewiscarter998@gmail.com</a>.
                        <br/>
                        <br/>
                        However if you would just like to chat, please message me on LinkedIn: <a href="https://www.linkedin.com/in/lewis-carter-a89abba9">https://www.linkedin.com/in/lewis-carter-a89abba9</a>.
                        <br/>
                        <br/>
                        Or if you would like to see some personal projects I have worked on, take a peek at my GitHub: <a href="https://github.com/Sanans1">https://github.com/Sanans</a>.
                    </p>
                </div>
            </div>
        )
    }
}

export default Home;