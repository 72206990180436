import React from 'react';
import me from '../../assets/me.jpg';

class Home extends React.Component {
    render() {
        return (
            <div className="background main">
                <div className="align-center">
                    <img src={me} alt="Lewis Carter" className="avatar" />
                </div>
                <div className="jumbotron">
                    <h1 className="display-3">About me!</h1>
                    <hr className="my-4"></hr>
                    <p className="jumbotron-p">
                        Hello! My name is Lewis Carter and this is my website I created using React as a personal project. 
                        <br/>
                        Here you can find links to my GitHub and LinkedIn as well as further information you may require if you wish to contact me.
                        <br/>
                        <br/>
                        Now a bit about me!
                        <br/>
                        <br/>
                        I studied two years of Technical Games Development at Teesside University and I am now going to complete my final year on a different course (Computer Science!).
                        <br/>
                        This came about due to me spending my placement year with the (Awesome) software development team at Tracerco. 
                        There I learned many valuable skills and gained experience in others, primarily C#, desktop development and DevOps through Azure DevOps!
                        <br/>
                        However I have a personal goal to keep myself open to learning new skills and to keep myself as broad as possible (One of the reasons I made this site) and so I am always on the look out for opportunities to improve myself.
                    </p>
                </div>
            </div>
        )
    }
}

export default Home;