import React from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from "react-burger-menu";

import './hamburger.css';
import github from '../assets/github.svg'
import linkedin from '../assets/linkedin.png'

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.hamburgerToggle = this.hamburgerToggle.bind(this);
  }
  hamburgerToggle() {
    var x = document.getElementById("hamburgerLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  render() {
    return (
      <div className="navbar sidebar">
        <div>
          <h1 className="logo-base">
            <span className="logo-pt1">LEWIS</span> 
            <br/>
            <span className="logo-pt2">CARTER</span> 
            <br/>
            <span className="logo-pt3">.DEV</span>
          </h1>
        </div>
        <ul className="nav">
          <li className="nav-item">
            <Link className="sidebar-link" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="sidebar-link" to="/contact">Contact</Link>
          </li>
        </ul>
        <div className="sidebar-footer">
          <a href="https://github.com/Sanans1">
            <img src={github} alt="GitHub" className="sidebar-img"/>
          </a>
          <a href="https://www.linkedin.com/in/lewis-carter-a89abba9">
            <img src={linkedin} alt="LinkedIn" className="sidebar-img"/>
          </a>
        </div>
      </div>
    );
  }
}

export default Sidebar;
